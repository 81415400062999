<template>
  <getecma-form v-if="neighborhood" :submit="save">

    <fieldset class="form-group mt--xl">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="neighborhood.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Dom Bosco" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Cidade*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            v-bind="$attrs"
            name="cidade"
            rules="required"
            placeholder="Selecione a cidade"
            :value="citySelected.name"
            :options="cities"
            @on-change="onCityChange">
          </getecma-select>
        </div>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>

import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchCities, getCityById } from '@/modules/city/city.service';

export default {
  name: 'GetecmaNeighborhoodCreateInformation',
  components: {
  },
  inject: ['neighborhoodEditVm'],
  data() {
    return {
      neighborhood: this.neighborhoodEditVm.neighborhood,
      performer: getters.getUser(),
      cities: [],
      citySelected: null,
    };
  },
  mounted() {
    this.fetchCities();
    this.fetchCity();
  },
  methods: {
    goHistoryBack,
    async fetchCity() {
      getCityById(this.neighborhood.city_id)
        .then(data => {
          this.citySelected = data;
        })
        .catch(() => toastError('Erro ao obter cidade por ID'));
    },
    async fetchCities() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchCities(params);
        this.cities = response.rows.map(city => ({
          key: city.id,
          value: city.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar as cidades:', error);
      }
    },
    save() {
      this.$emit('save');
    },
    onCityChange(citySelected) {
      this.neighborhood.city_id = citySelected.key;
    },
  },
};
</script>
