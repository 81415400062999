<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="neighborhood" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="neighborhood.id">
        <getecma-header size="lg">Editar Bairro</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-neighborhood-edit-information @save="save" />
      </div>
      <div v-if="!neighborhood_id">
        <getecma-header size="lg">Adicionar Bairro</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-neighborhood-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenNeighborhood, goToNeighborhoodForbiddenPage } from '@/modules/neighborhood/neighborhood.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getNeighborhoodById, updateNeighborhoodInformation, createNewNeighborhood } from '@/modules/neighborhood/neighborhood.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { NEIGHBORHOODS_URL } from '@/modules/neighborhood/neighborhood.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaNeighborhoodEditInformation from '@/modules/neighborhood/components/NeighborhoodEditComponent.vue';
import GetecmaNeighborhoodCreateInformation from '@/modules/neighborhood/components/NeighborhoodCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaNeighborhoodEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaNeighborhoodEditInformation,
    GetecmaNeighborhoodCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const neighborhoodEditVm = {};
    Object.defineProperty(neighborhoodEditVm, 'neighborhood', {
      get: () => this.neighborhood,
    });
    return { neighborhoodEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Bairros', path: NEIGHBORHOODS_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      neighborhood: null,
      createdNeighborhood: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    neighborhood_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.neighborhood_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToNeighborhoodForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToNeighborhoodForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.neighborhood_id) {
        getNeighborhoodById(this.neighborhood_id)
          .then(data => {
            this.neighborhood = data;
          })
          .catch(() => toastError('Erro ao obter bairro por ID'));
      } else {
        this.neighborhood = {
          name: '',
        };
      }
    },
    save() {
      if (this.neighborhood_id) {
        this.fullscreenLoading = true;
        updateNeighborhoodInformation(this.neighborhood)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Bairro salvo!');
            goToOpenNeighborhood(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o bairro');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewNeighborhood(this.neighborhood);
      }
    },
    onCreateNewNeighborhood(neighborhood) {
      createNewNeighborhood(neighborhood)
        .then(data => {
          goToOpenNeighborhood(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar o bairro');
        });
    },
  },
};
</script>
