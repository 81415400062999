<template>
  <div>
    <getecma-form v-if="neighborhood" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="neighborhood.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Dom Bosco" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Cidade*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="cidade"
            rules="required"
            placeholder="Selecione a cidade"
            :options="cities"
            @on-change="onCityChange">
          </getecma-select>
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchCities } from '@/modules/city/city.service';

export default {
  name: 'GetecmaNeighborhoodCreateInformation',
  components: {
  },
  inject: ['neighborhoodEditVm'],
  data() {
    return {
      neighborhood: this.neighborhoodEditVm.neighborhood,
      performer: getters.getUser(),
      cities: [],
    };
  },
  mounted() {
    this.fetchCities();
  },
  methods: {
    goHistoryBack,
    async fetchCities() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchCities(params);
        this.cities = response.rows.map(city => ({
          key: city.id,
          value: city.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar as cidades:', error);
      }
    },
    create() {
      this.$emit('create');
    },
    onCityChange(city) {
      this.neighborhood.city_id = city.key;
    },
  },
};
</script>
